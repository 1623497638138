import * as React from "react";

import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";

import { UitkSpacing } from "@egds/react-core/spacing";

import { SingleOfferForm } from "components/shared/DatePickerAndTravelerForm/Form";
import { PropertySingleOfferProps } from "../typings";
import { ScreenSizeWrapper } from "../components/ScreenSizeWrapper";
import { UitkHeading } from "@egds/react-core/text";
import { useLocalization } from "@shared-ui/localization-context";
import { Experiment as ExperimentComponent, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { TrackedVisibility } from "src/components/utility/analytics/TrackedVisibility";

export const PropertySingleOffer = (props: PropertySingleOfferProps) => {
  const { context, templateComponent } = props;
  const { formatText } = useLocalization();

  if (!templateComponent) {
    return null;
  }

  return (
    <ScreenSizeWrapper>
      <UitkSpacing margin={{ large: { block: "three", inlinestart: "three" } }}>
        <UitkCard overflow={true}>
          <UitkSpacing padding={{ blockstart: "three", blockend: "six", large: { inline: "three" } }}>
            <UitkCardContentSection padded={false}>
              <UitkSpacing padding="three">
                <div data-stid="single-offer-search">
                  <ExperimentComponent name="Vrbo_Add_dates_for_prices_on_Flex_Dateless_PDP" logExposure={true}>
                    <ExperimentControl />
                    <ExperimentVariant bucket={1}>
                      <TrackedVisibility rfrrId={`adddatesforprices.heading`} moduleName={`Add dates for prices`}>
                        <UitkSpacing padding={{ blockend: "two" }}>
                          <UitkHeading tag="h2" size={5}>
                            {formatText("hotels.datelessPDP.propertySingleOffers.add.dates.heading")}
                          </UitkHeading>
                        </UitkSpacing>
                      </TrackedVisibility>
                    </ExperimentVariant>
                  </ExperimentComponent>
                  <SingleOfferForm
                    context={context}
                    enableWeekdayFormatting={templateComponent.config.enableWeekdayFormatting}
                    maxDateRange={templateComponent.config?.maxDateRange}
                  />
                </div>
              </UitkSpacing>
            </UitkCardContentSection>
          </UitkSpacing>
        </UitkCard>
      </UitkSpacing>
    </ScreenSizeWrapper>
  );
};

PropertySingleOffer.displayName = "PropertySingleOffer";
export default PropertySingleOffer;
