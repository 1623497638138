import * as React from "react";

import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { UitkLayoutPosition } from "@egds/react-core/layout-position";

export const ScreenSizeWrapper = ({ children }: any) => {
  return (
    <Viewport>
      <ViewSmall>
        <UitkLayoutPosition type="relative" data-testid="position-relative">
          {children}
        </UitkLayoutPosition>
      </ViewSmall>
      <ViewMedium>
        <UitkLayoutPosition
          position={{ top: "twelve", right: "zero", zindex: "layer1" }}
          type="sticky"
          data-testid="position-sticky"
        >
          {children}
        </UitkLayoutPosition>
      </ViewMedium>
    </Viewport>
  );
};

ScreenSizeWrapper.displayName = "ScreenSizeWrapper";
export default ScreenSizeWrapper;
